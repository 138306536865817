import React, { useState, useEffect } from "react";
import {
  SwipeableDrawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Typography,
  MenuItem,
  Select,
  Box,
  InputAdornment,
} from "@mui/material";
import { useNavigate } from "react-router-dom"; // Untuk navigasi antar halaman
import LightModeRoundedIcon from "@mui/icons-material/LightModeRounded";
import DarkModeRoundedIcon from "@mui/icons-material/DarkModeRounded";
import AutoAwesomeRoundedIcon from "@mui/icons-material/AutoAwesomeRounded";
import ThumbsUpDownRoundedIcon from "@mui/icons-material/ThumbsUpDownRounded";
import SwapHorizRoundedIcon from "@mui/icons-material/SwapHorizRounded";
import BookRoundedIcon from "@mui/icons-material/BookRounded";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import NotesRoundedIcon from '@mui/icons-material/NotesRounded';
import Cookies from "js-cookie"; // Import js-cookie

const SwipeableDrawerMenu = ({ open, toggleDrawer, darkModeRounded, setDarkMode }) => {
  const navigate = useNavigate(); // Hook untuk navigasi
  const [theme, setTheme] = useState("auto");

  // Load tema dari cookie saat pertama kali
  useEffect(() => {
    const savedTheme = Cookies.get("theme") || "auto";
    setTheme(savedTheme);
    if (savedTheme === "light") setDarkMode(false);
    else if (savedTheme === "dark") setDarkMode(true);
    else setDarkMode(window.matchMedia("(prefers-color-scheme: dark)").matches);
  }, [setDarkMode]);

  // Ubah tema berdasarkan pilihan
  const handleThemeChange = (event) => {
    const selectedTheme = event.target.value;
    setTheme(selectedTheme);
    Cookies.set("theme", selectedTheme, { path: "/", expires: 30 }); // Simpan cookie
    if (selectedTheme === "light") setDarkMode(false);
    else if (selectedTheme === "dark") setDarkMode(true);
    else setDarkMode(window.matchMedia("(prefers-color-scheme: dark)").matches);
  };

  const features = [
    { name: "Home", link: "", icon: <HomeRoundedIcon /> },
    { name: "Konteks", link: "konteks", icon: <NotesRoundedIcon />},
    { name: "Definisi", link: "definisi", icon: <BookRoundedIcon /> },
    { name: "Sinonim", link: "sinonim", icon: <SwapHorizRoundedIcon /> },
    { name: "Antonim", link: "antonim", icon: <ThumbsUpDownRoundedIcon /> },
  ];

  return (
    <SwipeableDrawer
      anchor="right"
      open={open}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
    >
      <Box
        sx={{
          width: 250,
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        {/* Theme Selector */}
        <Box sx={{ p: 2 }}>
          <Typography variant="subtitle2" gutterBottom>
            Theme
          </Typography>
          <Select
            value={theme}
            onChange={handleThemeChange}
            fullWidth
            size="small"
            startAdornment={
              <InputAdornment position="start" sx={{ mr: 1 }}>
                {theme === "light" ? (
                  <LightModeRoundedIcon />
                ) : theme === "dark" ? (
                  <DarkModeRoundedIcon />
                ) : (
                  <AutoAwesomeRoundedIcon />
                )}
              </InputAdornment>
            }
          >
            <MenuItem value="auto">Auto</MenuItem>
            <MenuItem value="light">Light</MenuItem>
            <MenuItem value="dark">Dark</MenuItem>
          </Select>
        </Box>
        <Divider />

        {/* Tools Subtitle */}
        <Typography variant="subtitle2" sx={{ px: 2, mt: 2 }}>
          Tools
        </Typography>

        {/* Features List */}
        <List>
          {features.map((feature, index) => (
            <ListItem
              button
              key={index}
              onClick={() => {
                navigate(`/${feature.link.toLowerCase()}`);
                toggleDrawer(false)();
              }}
            >
              <ListItemIcon>{feature.icon}</ListItemIcon>
              <ListItemText primary={feature.name} />
            </ListItem>
          ))}
        </List>

        {/* Copyright */}
        <Box sx={{ mt: "auto", p: 2, textAlign: "center", opacity: 0.8 }}>
          <Typography variant="body2">
            &copy; {new Date().getFullYear()} Diksi
          </Typography>
        </Box>
      </Box>
    </SwipeableDrawer>
  );
};

export default SwipeableDrawerMenu;
