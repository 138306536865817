import React from "react";
import { Box, Typography, Avatar, Grid, Button } from "@mui/material";
import MailOutlineRoundedIcon from '@mui/icons-material/MailOutlineRounded';
import LanguageRoundedIcon from '@mui/icons-material/LanguageRounded';
import { Helmet } from "react-helmet";

const ContactPage = () => {
  return (
    <Box p={3} my={17} display="flex" flexDirection="column" alignItems="center">
      <Helmet>
        <title>Kontak - diksi</title>
        <meta
          name="description"
          content="Informasi kontak dari developer Diksi."
        />
      </Helmet>
      <Avatar
        alt="Abdi Putrana Radian"
        src="/abdi.jpg"
        sx={{ width: 100, height: 100, mb: 2 }}
      />
      <Typography variant="h5">
        Developer
      </Typography>
      <Typography variant="body2" align="center" color="textSecondary" sx={{ mb: 2 }}>
        Hubungi saya jika ada pertanyaan atau masukan.
        </Typography>
      <Grid container spacing={2} justifyContent="center">
        <Grid item>
          <Button variant="contained" color="primary" href="mailto:info@diksi.web.id" startIcon={<MailOutlineRoundedIcon />} sx={{ bgcolor: "#F6B17A", color: "#15182C" }}>
            Email
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContactPage;