import React, { useEffect, useState } from "react";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import { Box, Typography, Button } from "@mui/material";
import { Helmet } from "react-helmet";
import tesaurusData from "../data/tesaurus.json";

const KonteksPage = () => {
  const { kata } = useParams();
  const [konteks, setKonteks] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const results = [];

    tesaurusData.forEach((category) => {
      const categoryResults = category.results.filter((result) =>
        result.words.some((wordArray) => wordArray.includes(kata))
      );

      if (categoryResults.length > 0) {
        results.push({
          category: category.category,
          results: categoryResults,
        });
      }
    });

    setKonteks(results);
  }, [kata]);

  return (
    <Box sx={{ p: 2 }}>
      <Helmet>
        <title>Konteks dari {kata} - diksi</title>
        <meta
          name="description"
          content={`Cari tahu konteks dari ${kata} di diksi.`}
        />
      </Helmet>
      <Typography variant="h4" gutterBottom>
        Konteks dari{" "}
        <Typography variant="div" color="primary" sx={{ fontWeight: "bold" }}>
          {kata}
        </Typography>
      </Typography>
      <Box sx={{ display: "flex", gap: 2, mb: 4 }}>
        <Button
          variant="outlined"
          onClick={() => navigate(`/konteks/${kata}`)}
          disabled={location.pathname === `/konteks/${kata}`}
        >
          Lihat Konteks
        </Button>
        <Button
          variant="outlined"
          onClick={() => navigate(`/definisi/${kata}`)}
          disabled={location.pathname === `/definisi/${kata}`}
        >
          Lihat Definisi
        </Button>
        <Button
          variant="outlined"
          onClick={() => navigate(`/sinonim/${kata}`)}
          disabled={location.pathname === `/sinonim/${kata}`}
        >
          Lihat Sinonim
        </Button>
        <Button
          variant="outlined"
          onClick={() => navigate(`/antonim/${kata}`)}
          disabled={location.pathname === `/antonim/${kata}`}
        >
          Lihat Antonim
        </Button>
      </Box>
      {konteks.length > 0 ? (
        konteks.map((category, index) => (
          <Box key={index} sx={{ mb: 4 }}>
            <Typography variant="h5" color="primary">
              {category.category}
            </Typography>
            {category.results.map((result, idx) => (
              <Box key={idx} sx={{ mb: 2 }}>
                <Typography
                  variant="subtitle2"
                  color="text.secondary"
                  gutterBottom
                  sx={{ fontStyle: "italic" }}
                >
                  {result.type}
                </Typography>
                {result.words
                  .filter((wordArray) => wordArray.includes(kata))
                  .map((wordArray, wordIdx) => (
                    <Typography key={wordIdx} variant="body1">
                      {wordArray.map((word, wIdx) => (
                        <span key={wIdx}>
                          <Link
                            to={`/konteks/${word}`}
                            style={{
                              textDecoration: "none",
                              backgroundColor:
                                word === kata ? "#2b4e64" : "inherit",
                              color: word === kata ? "#f8c794" : "inherit",
                              padding: word === kata ? "0 4px" : "0",
                              borderRadius: "4px",
                            }}
                          >
                            {word}
                          </Link>
                          {wIdx < wordArray.length - 1 ? ", " : ""}
                        </span>
                      ))}
                    </Typography>
                  ))}
              </Box>
            ))}
          </Box>
        ))
      ) : (
        <Typography variant="body1">
          Tidak ada konteks yang ditemukan.
        </Typography>
      )}
    </Box>
  );
};

export default KonteksPage;
