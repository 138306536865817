import { createTheme } from '@mui/material/styles';

const lightTheme = createTheme({
  typography: {
    fontFamily: 'Rubik, sans-serif',
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#153448',
    },
    secondary: {
      main: '#1E3F55',
    },
    background: {
      default: '#FAF4EE',
      paper: '#F5DEC6',
    },
    text: {
      primary: '#153448',
      secondary: '#1E3F55',
    },
  },
  
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '*': {
          transition: 'all 0.2s ease',
        },
        '*::selection': {
          backgroundColor: 'rgba(36 70 94 / 0.8)',
          color: '#FFFFFF',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          border: '1px solid #E4B98B',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& fieldset': {
            borderColor: 'gray',
          },
          '&:hover fieldset': {
            borderColor: '#153448',
          },
          '&.Mui-focused fieldset': {
            borderColor: '#153448',
          },
        },
      },
    },
  },
});

const darkTheme = createTheme({
  typography: {
    fontFamily: 'Rubik, sans-serif',
  },
  palette: {
    mode: 'dark',
    primary: {
      main: '#f8c794',
    },
    secondary: {
      main: '#2b4e64',
    },
    background: {
      default: '#153448',
      paper: '#2b4e64',
    },
    text: {
      primary: '#ffffff',
      secondary: '#AEB0B9',
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '*': {
          transition: 'all 0.2s ease',
        },
        '*::selection': {
            backgroundColor: 'rgba(248 200 148 / 0.8)',
            color: '#153448',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          border: '1px solid #6f8c9d',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          opacity: 0.8,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          opacity: 0.8,
        },
      },
    },
    MuiBottomNavigationAction: {
      styleOverrides: {
        root: {
          color: 'rgba(219 197 171 / 0.8)',
          '&.Mui-selected': {
            color: '#ffe0b5',
          },
        },
      },
    },
    MuiBottomNavigation: {
      styleOverrides: {
        root: {
          backgroundColor: '#2b4e64',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& fieldset': {
            borderColor: 'rgba(248 200 148 / 0.5)',
          },
          '&:hover fieldset': {
            borderColor: '#f8c794',
          },
          '&.Mui-focused fieldset': {
            borderColor: '#f8c794',
          },
        },
      },
    },
  },
});

export { lightTheme, darkTheme };
