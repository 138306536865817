import React from "react";
import { Box, Typography } from "@mui/material";
import { Helmet } from "react-helmet";

const PrivacyPolicyPage = () => {
  return (
    <Box sx={{ p: 2 }}>
      <Helmet>
        <title>Kebijakan Privasi - diksi</title>
        <meta name="description" content="Kebijakan privasi dari Diksi." />
      </Helmet>
      <Typography variant="h4" color="primary" gutterBottom>
        Kebijakan Privasi
      </Typography>
      <Typography variant="body1" paragraph>
        Di Diksi, kami menghargai privasi Anda. Berikut adalah informasi
        mengenai data yang kami simpan dan bagaimana kami menggunakannya.
      </Typography>
      <Typography variant="h5" color="primary" gutterBottom>
        Preferensi Tema
      </Typography>
      <Typography variant="body1" paragraph>
        Kami menyimpan preferensi tema Anda (mode terang atau mode gelap)
        menggunakan cookie. Ini memungkinkan kami untuk menampilkan situs web
        dengan tema yang Anda pilih setiap kali Anda mengunjungi Diksi.
      </Typography>
      <Typography variant="h5" color="primary" gutterBottom>
        Kata yang Disimpan (Bookmark)
      </Typography>
      <Typography variant="body1" paragraph>
        Kami juga menyimpan kata-kata yang Anda simpan (bookmark) menggunakan
        cookie. Ini memungkinkan Anda untuk dengan mudah mengakses kata-kata
        yang telah Anda simpan saat Anda kembali ke Diksi.
      </Typography>
      <Typography variant="body1" paragraph>
        Kami tidak menyimpan data pribadi Anda atau informasi sensitif lainnya.
        Cookie yang kami gunakan hanya untuk menyimpan preferensi tema dan kata
        yang disimpan.
      </Typography>
      <Typography variant="body1" paragraph>
        Jika Anda memiliki pertanyaan lebih lanjut mengenai kebijakan privasi
        kami, jangan ragu untuk menghubungi kami.
      </Typography>
    </Box>
  );
};

export default PrivacyPolicyPage;
