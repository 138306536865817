import React from "react";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Link,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Helmet } from "react-helmet";

const AboutPage = () => {
  return (
    <Box sx={{ p: 2 }}>
      <Helmet>
        <title>Tentang Diksi - diksi</title>
        <meta
          name="description"
          content="Informasi tentang Diksi, platform informasi kata-kata dalam Bahasa Indonesia."
        />
      </Helmet>
      <Typography variant="h4" color="primary" gutterBottom>
        Tentang Diksi
      </Typography>
      <Typography variant="body1" paragraph>
        Diksi adalah sebuah platform yang menyediakan berbagai informasi tentang
        kata-kata dalam Bahasa Indonesia, termasuk definisi, sinonim, antonim,
        dan konteks penggunaannya.
      </Typography>
      <Typography variant="h5" color="primary" gutterBottom>
        FAQ
      </Typography>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Bagaimana cara menggunakan Diksi?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Anda dapat mencari kata yang ingin Anda ketahui definisi, sinonim,
            antonim, atau konteksnya dengan menggunakan kotak pencarian di
            halaman utama.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Apakah Diksi gratis digunakan?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Ya, Diksi dapat digunakan secara gratis oleh siapa saja yang ingin
            mencari informasi tentang kata-kata dalam Bahasa Indonesia.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Dari mana sumber data Diksi?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Sumber data Diksi berasal dari berbagai sumber terpercaya, termasuk
            KBBI Daring edisi III, Tesaurus Bahasa Indonesia, dan lainnya.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Typography variant="h5" color="primary" gutterBottom sx={{ mt: 4 }}>
        Credits
      </Typography>
      <Typography variant="body1" paragraph>
        Konteks:{" "}
        <Link
          href="https://tesaurus.kemdikbud.go.id/"
          target="_blank"
          rel="noopener"
        >
          https://tesaurus.kemdikbud.go.id/
        </Link>
      </Typography>
      <Typography variant="body1" paragraph>
        Definisi:{" "}
        <Link
          href="https://github.com/dyazincahya/KBBI-SQL-database"
          target="_blank"
          rel="noopener"
        >
          https://github.com/dyazincahya/KBBI-SQL-database
        </Link>
      </Typography>
      <Typography variant="body1" paragraph>
        Sinonim:{" "}
        <Link
          href="https://github.com/adnanzulkarnain/Tesaurus-Bahasa"
          target="_blank"
          rel="noopener"
        >
          https://github.com/adnanzulkarnain/Tesaurus-Bahasa
        </Link>
      </Typography>
      <Typography variant="body1" paragraph>
        Antonim:{" "}
        <Link
          href="https://github.com/riochr17/Daftar-Antonim-Tesaurus-Bahasa-Indonesia"
          target="_blank"
          rel="noopener"
        >
          https://github.com/riochr17/Daftar-Antonim-Tesaurus-Bahasa-Indonesia
        </Link>
      </Typography>
      <Typography variant="body1" paragraph>
        Database utama khusus definisi menggunakan KBBI Daring edisi III, Hak
        Cipta Badan Pengembangan dan Pembinaan Bahasa (Pusat Bahasa).
      </Typography>
    </Box>
  );
};

export default AboutPage;
