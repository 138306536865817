import React, { useState } from 'react';
import { AppBar, Toolbar, TextField, InputAdornment, IconButton, Box } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';

const Header = ({ toggleDrawer }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      navigate(`/definisi/${searchTerm}`);
    }
  };

  return (
    <AppBar position="sticky">
      <Toolbar>
        <Box sx={{ flexGrow: 1, textAlign: 'left', mb: -0.5 }}>
          <Link to="/" style={{ display: 'inline-block' }}>
            <img src="/diksi-logo.png" alt="Diksi Logo" style={{ height: '40px' }} draggable="false" />
          </Link>
        </Box>
        <TextField
          variant="outlined"
          placeholder="Cari..."
          size="small"
          id="word"
          value={searchTerm}
          onChange={handleSearchChange}
          onKeyPress={handleKeyPress}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchRoundedIcon color="inherit" />
              </InputAdornment>
            ),
          }}
          sx={{
            width: 240,
            mr: 2,
            '& .MuiOutlinedInput-root': {
              color: 'white',
              '& fieldset': {
                borderColor: 'rgba(248, 200, 148, 0.5)',
              },
              '&:hover fieldset': {
                borderColor: '#f8c794',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#f8c794',
              },
              '&::selection': {
                backgroundColor: '#f8c794',
                color: '#153448',
              },
            },
            '& .MuiInputAdornment-root': {
              color: 'inherit',
            },
          }}
        />
        <IconButton color="inherit" onClick={toggleDrawer} sx={{ mr: 1 }}>
          <MenuRoundedIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
