import React, { useEffect, useState } from "react";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import { Box, Typography, Grid, Button } from "@mui/material";
import { Helmet } from "react-helmet";
import sinonimData from "../data/sinonim.json";

const SinonimPage = () => {
  const { kata } = useParams();
  const [sinonim, setSinonim] = useState([]);
  const [tag, setTag] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (sinonimData[kata] && sinonimData[kata].sinonim) {
      setSinonim(sinonimData[kata].sinonim);
      setTag(sinonimData[kata].tag);
    } else {
      // Perform reverse search
      const reverseSinonim = [];
      let foundTag = "";
      for (const key in sinonimData) {
        if (sinonimData[key].sinonim.includes(kata)) {
          reverseSinonim.push(key);
          if (!foundTag) {
            foundTag = sinonimData[key].tag;
          }
        }
      }
      setSinonim(reverseSinonim);
      setTag(foundTag);
    }
  }, [kata]);

  return (
    <Box sx={{ p: 2 }}>
      <Helmet>
        <title>Sinonim dari {kata} - diksi</title>
        <meta
          name="description"
          content={`Cari tahu sinonim dari ${kata} di diksi.`}
        />
      </Helmet>
      <Typography variant="h4" gutterBottom>
        Sinonim dari{" "}
        <Typography variant="div" color="primary" sx={{ fontWeight: "bold" }}>
          {kata}
        </Typography>
      </Typography>
      <Box sx={{ display: "flex", gap: 2, mb: 4 }}>
        <Button
          variant="outlined"
          onClick={() => navigate(`/konteks/${kata}`)}
          disabled={location.pathname === `/konteks/${kata}`}
        >
          Lihat Konteks
        </Button>
        <Button
          variant="outlined"
          onClick={() => navigate(`/definisi/${kata}`)}
          disabled={location.pathname === `/definisi/${kata}`}
        >
          Lihat Definisi
        </Button>
        <Button
          variant="outlined"
          onClick={() => navigate(`/sinonim/${kata}`)}
          disabled={location.pathname === `/sinonim/${kata}`}
        >
          Lihat Sinonim
        </Button>
        <Button
          variant="outlined"
          onClick={() => navigate(`/antonim/${kata}`)}
          disabled={location.pathname === `/antonim/${kata}`}
        >
          Lihat Antonim
        </Button>
      </Box>
      {tag && (
        <Typography
          variant="subtitle2"
          color="text.secondary"
          sx={{ fontStyle: "italic" }}
          gutterBottom
        >
          {tag}
        </Typography>
      )}
      {sinonim.length > 0 ? (
        <Grid container spacing={2}>
          {sinonim.map((synonym, index) => (
            <Grid item xs={4} sm={3} md={1} key={index}>
              <Link
                to={`/sinonim/${synonym}`}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <Typography variant="body1" color="primary">
                  {synonym}
                </Typography>
              </Link>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Typography variant="body1">
          Tidak ada sinonim yang ditemukan.
        </Typography>
      )}
    </Box>
  );
};

export default SinonimPage;
