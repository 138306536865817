import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <Box sx={{ textAlign: 'center', py: 4, mt: 22, backgroundColor: '#0C202E', color: 'white' }}>
      <Grid container spacing={4} justifyContent="center">
        <Grid item>
          <Typography variant="h6" sx={{ color: "#f8c794" }} gutterBottom>
            Fitur
          </Typography>
          <Link to="/definisi" style={{ textDecoration: 'none', color: 'inherit', display: 'block' }}>
            Definisi
          </Link>
          <Link to="/sinonim" style={{ textDecoration: 'none', color: 'inherit', display: 'block' }}>
            Persamaan Kata
          </Link>
          <Link to="/antonim" style={{ textDecoration: 'none', color: 'inherit', display: 'block' }}>
            Lawan Kata
          </Link>
        </Grid>
        <Grid item>
          <Typography variant="h6" sx={{ color: "#f8c794" }} gutterBottom>
            Informasi
          </Typography>
          <Link to="/about" style={{ textDecoration: 'none', color: 'inherit', display: 'block' }}>
            Tentang
          </Link>
          <Link to="/contact" style={{ textDecoration: 'none', color: 'inherit', display: 'block' }}>
            Kontak
          </Link>
          <Link to="/privacy-policy" style={{ textDecoration: 'none', color: 'inherit', display: 'block' }}>
            Kebijakan Privasi
          </Link>
        </Grid>
      </Grid>
      <Typography variant="body2" sx={{ mt: 4, color: "#AEB0B9" }}>
        diksi &copy; {new Date().getFullYear()} versi 1.0.0<br />
        Database definisi menggunakan KBBI III Daring, Hak Cipta Badan Pengembangan dan Pembinaan Bahasa (Pusat Bahasa)
      </Typography>
    </Box>
  );
};

export default Footer;