import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ThemeProvider, CssBaseline, Box, GlobalStyles } from "@mui/material";
import { lightTheme, darkTheme } from "./theme";
import Header from "./components/Header";
import Footer from "./components/Footer";
import SwipeableDrawerMenu from "./components/SwipeableDrawerMenu";
import HomePage from "./pages/HomePage";
import SinonimPage from "./pages/SinonimPage";
import AntonimPage from "./pages/AntonimPage";
import DefinisiPage from "./pages/DefinisiPage";
import KonteksPage from "./pages/KonteksPage";
import AboutPage from "./pages/AboutPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import ContactPage from "./pages/ContactPage";

function App() {
  const [darkMode, setDarkMode] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <CssBaseline enableColorScheme />
        <GlobalStyles styles={{ body: { overflowX: "hidden" } }} />
        <Router>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              minHeight: "100vh",
              width: "100vw",
            }}
          >
            <Header toggleDrawer={toggleDrawer(true)} />
            <SwipeableDrawerMenu
              open={drawerOpen}
              toggleDrawer={toggleDrawer}
              darkMode={darkMode}
              setDarkMode={setDarkMode}
            />
            <Box
              component="main"
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/definisi/:kata" element={<DefinisiPage />} />
                <Route path="/sinonim/:kata" element={<SinonimPage />} />
                <Route path="/antonim/:kata" element={<AntonimPage />} />
                <Route path="/konteks/:kata" element={<KonteksPage />} />
                <Route path="/about" element={<AboutPage />} />
                <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
                <Route path="/contact" element={<ContactPage />} />
              </Routes>
            </Box>
            <Footer />
          </Box>
        </Router>
    </ThemeProvider>
  );
}

export default App;
