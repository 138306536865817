import React, { useState } from "react";
import {
  Box,
  Typography,
  Select,
  MenuItem,
  TextField,
  Button,
  Grid,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTheme } from '@mui/material/styles';

const HomePage = () => {
  const [tool, setTool] = useState('konteks');
  const [word, setWord] = useState('');
  const navigate = useNavigate();
  const theme = useTheme();

  const handleToolChange = (event) => {
    setTool(event.target.value);
  };

  const handleWordChange = (event) => {
    setWord(event.target.value);
  };

  const handleSearch = (event) => {
    event.preventDefault();
    if (tool && word) {
      const wordSearch = encodeURIComponent(word.toLowerCase());
      navigate(`/${tool}/${wordSearch}`);
    }
  };

  return (
    <Box
      sx={{
        textAlign: "center",
        mt: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        mt: "20vh",
        h: "100vh",
      }}
    >
      <img
        src={theme.palette.mode === 'light' ? '/diksi-logo-dark.png' : '/diksi-logo.png'}
        alt="Diksi Logo"
        style={{ height: '100px' }}
        draggable="false"
      />
      <Box
        component="form"
        onSubmit={handleSearch}
        sx={{ mt: 2, width: "100%", maxWidth: 600, px: 4 }}
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6}>
            <Select
              value={tool}
              onChange={handleToolChange}
              displayEmpty
              fullWidth
              size="small"
            >
              <MenuItem value="konteks">Konteks dari</MenuItem>
              <MenuItem value="definisi">Definisi dari</MenuItem>
              <MenuItem value="sinonim">Persamaan kata dari</MenuItem>
              <MenuItem value="antonim">Lawan kata dari</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              placeholder="Masukkan kata..."
              value={word}
              onChange={handleWordChange}
              fullWidth
              size="small"
              id="word"
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="primary" type="submit" fullWidth>
              Cari
            </Button>
          </Grid>
        </Grid>
        <Typography variant="body1" sx={{ mt: 4 }}>
          Temukan Kata Tepat untuk Setiap Konteks.
        </Typography>
      </Box>
    </Box>
  );
};

export default HomePage;
