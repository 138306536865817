import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Box, Typography, Button } from "@mui/material";
import { Helmet } from "react-helmet";
import definisiData from "../data/definisi.json";

const DefinisiPage = () => {
  const { kata } = useParams();
  const [definisi, setDefinisi] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const filteredDefinisi = definisiData.dictionary.filter(
      (entry) => entry.word.trim() === kata && entry.type === 1
    );

    // Remove duplicates
    const uniqueDefinisi = [];
    const seen = new Set();
    filteredDefinisi.forEach((entry) => {
      if (!seen.has(entry.arti)) {
        uniqueDefinisi.push(entry);
        seen.add(entry.arti);
      }
    });

    setDefinisi(uniqueDefinisi);
  }, [kata]);

  const decodeHtml = (html) => {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  };

  return (
    <Box sx={{ p: 2 }}>
      <Helmet>
        <title>Definisi dari {kata} - diksi</title>
        <meta
          name="description"
          content={`Cari tahu definisi dari ${kata} di diksi.`}
        />
      </Helmet>
      <Typography variant="h4" gutterBottom>
        Definisi dari{" "}
        <Typography variant="div" color="primary" sx={{ fontWeight: "bold" }}>
          {kata}
        </Typography>
      </Typography>
      <Box sx={{ display: "flex", gap: 2, mb: 4 }}>
        <Button
          variant="outlined"
          onClick={() => navigate(`/konteks/${kata}`)}
          disabled={location.pathname === `/konteks/${kata}`}
        >
          Lihat Konteks
        </Button>
        <Button
          variant="outlined"
          onClick={() => navigate(`/definisi/${kata}`)}
          disabled={location.pathname === `/definisi/${kata}`}
        >
          Lihat Definisi
        </Button>
        <Button
          variant="outlined"
          onClick={() => navigate(`/sinonim/${kata}`)}
          disabled={location.pathname === `/sinonim/${kata}`}
        >
          Lihat Sinonim
        </Button>
        <Button
          variant="outlined"
          onClick={() => navigate(`/antonim/${kata}`)}
          disabled={location.pathname === `/antonim/${kata}`}
        >
          Lihat Antonim
        </Button>
      </Box>
      {definisi.length > 0 ? (
        definisi.map((entry) => (
          <Typography
            key={entry._id}
            variant="body1"
            dangerouslySetInnerHTML={{ __html: decodeHtml(entry.arti) }}
          />
        ))
      ) : (
        <Typography variant="body1">
          Tidak ada definisi yang ditemukan.
        </Typography>
      )}
    </Box>
  );
};

export default DefinisiPage;
