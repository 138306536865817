import React, { useEffect, useState } from "react";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import { Box, Typography, Grid, Button } from "@mui/material";
import { Helmet } from "react-helmet";
import antonimData from "../data/antonim.json";

const AntonimPage = () => {
  const { kata } = useParams();
  const [antonim, setAntonim] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (antonimData[kata] && antonimData[kata].antonim) {
      setAntonim(antonimData[kata].antonim);
    } else {
      // Perform reverse search
      const reverseAntonim = [];
      for (const key in antonimData) {
        if (antonimData[key].antonim.includes(kata)) {
          reverseAntonim.push(key);
        }
      }
      setAntonim(reverseAntonim);
    }
  }, [kata]);

  return (
    <Box sx={{ p: 2 }}>
      <Helmet>
        <title>Antonim dari {kata} - diksi</title>
        <meta
          name="description"
          content={`Cari tahu antonim dari ${kata} di diksi.`}
        />
      </Helmet>
      <Typography variant="h4" gutterBottom>
        Antonim dari{" "}
        <Typography variant="div" color="primary" sx={{ fontWeight: "bold" }}>
          {kata}
        </Typography>
      </Typography>
      <Box sx={{ display: "flex", gap: 2, mb: 4 }}>
        <Button
          variant="outlined"
          onClick={() => navigate(`/konteks/${kata}`)}
          disabled={location.pathname === `/konteks/${kata}`}
        >
          Lihat Konteks
        </Button>
        <Button
          variant="outlined"
          onClick={() => navigate(`/definisi/${kata}`)}
          disabled={location.pathname === `/definisi/${kata}`}
        >
          Lihat Definisi
        </Button>
        <Button
          variant="outlined"
          onClick={() => navigate(`/sinonim/${kata}`)}
          disabled={location.pathname === `/sinonim/${kata}`}
        >
          Lihat Sinonim
        </Button>
        <Button
          variant="outlined"
          onClick={() => navigate(`/antonim/${kata}`)}
          disabled={location.pathname === `/antonim/${kata}`}
        >
          Lihat Antonim
        </Button>
      </Box>
      {antonim.length > 0 ? (
        <Grid container spacing={2}>
          {antonim.map((antonym, index) => (
            <Grid item xs={4} sm={3} md={1} key={index}>
              <Link
                to={`/antonim/${antonym}`}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <Typography variant="body1" color="primary">
                  {antonym}
                </Typography>
              </Link>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Typography variant="body1">
          Tidak ada antonim yang ditemukan.
        </Typography>
      )}
    </Box>
  );
};

export default AntonimPage;
